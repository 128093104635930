<template>
  <div class="insightBox">
    <div class="leftChildNav" :class="showLeft ? '' : 'hide'">
      <div class="topTitle">{{ this.$route.meta.function }}</div>
      <!-- <div class="fixedLeft" @click="changeFixed">
        <img v-if="fixedLeft" src="../assets/image/fixed_active.png" alt />
        <img v-else src="../assets/image/fixed.png" alt />
      </div> -->
      <Menu horizontal="horizontal" :active-name="activeName" :open-names="openNames" :accordion=true
        @on-open-change="onOpenChange" @on-select="onSelect">
        <!--第一层 -->
        <template v-for="(item, index) in initMenuList()">
          <Submenu :key="index + '_1'" :name="item.path" v-if="item.children && item.meta.hidden == undefined">
            <template slot="title">

              <Icon :custom="item.meta.icon" size="20" />
              {{ item.name }}
            </template>
            <!--第二层 -->
            <template v-for="(itemChildren, index) in item.children">
              <!-- 第三层-->
              <Submenu :key="index + '_2'" :name="itemChildren.path"
                v-if="itemChildren.children && itemChildren.meta.hidden == undefined" >
                <template slot="title">
                  <span style="padding-left: 10px;">{{ itemChildren.name }}</span></template>
                <template v-for="(itemChildrenChildren, index) in itemChildren.children">
                  <Submenu :key="index + '_3'" :name="itemChildrenChildren.path"
                    v-if="itemChildrenChildren.children && itemChildrenChildren.meta.hidden == undefined">
                    <template slot="title">
                      <span >{{ itemChildrenChildren.name }}</span>
                    </template>
                  </Submenu>
                  <MenuItem :key="index + '_3'" :name="itemChildrenChildren.path"
                    v-if="!itemChildrenChildren.children && itemChildrenChildren.meta.hidden == undefined"
                    :to="itemChildrenChildren.path">
                  <span style="padding-left: 14px;">{{ itemChildrenChildren.name }}</span>
                  </MenuItem>
                </template>
              </Submenu>
              <MenuItem :key="index + '_2'" :name="itemChildren.path"
                v-if="!itemChildren.children && itemChildren.meta.hidden == undefined" :to="itemChildren.path">

              <span>{{ itemChildren.name }}</span></MenuItem>
            </template>
          </Submenu>
          <!--第一层 -->
          <MenuItem v-else-if="!item.children && item.meta.hidden == undefined" :key="index + '_1'" :name="item.path"
            :to="item.path">
          <Icon :custom="item.meta.icon" size="20" />
          <span style="padding-left: 4px;">{{ item.name }}</span></MenuItem>
        </template>
      </Menu>
    </div>
    <div class="rightContent">
      <img class="openNav" v-if="showLeft" src="../assets/image/shouqi.png" @click="changeState({
  prop: 'showLeft',
  value: false
})" />
      <img class="closeNav" v-if="!showLeft" @click="changeState({
  prop: 'showLeft',
  value: true
})" src="../assets/image/zhankai.png" />
      <transition name="router-move">
        <!--        <iframe title="iframe" v-if="showIframe" :src="iframeHref" width="100%" height="100%"
                class="iframe_box"></iframe>-->
        <!-- <router-view :key="$route.path + date" v-else /> -->
      </transition>
      <router-view />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations
} from "vuex";
import reLand from "@/router/dataInsight/land/reLand";
import macroEconomy from "@/router/dataInsight/marcro/macroEconomy";
import financialMarket from "@/router/dataInsight/financial/financialMarket";
import city from "@/router/dataInsight/city/city";
import enterprise from "@/router/dataInsight/enterprise/enterprise";
import publicSentiment from "@/router/dataInsight/sentiment/publicSentiment";

export default {
  name: "home",
  data() {
    return {
      name: "",
      names: [],
      openNames: [],
      activeName: "",
      showIframe: false,
      menuList: [],
      iframeData: localStorage.getItem("iframeData"),
      iframeHref: "",
    };
  },
  computed: {
    ...mapState(["actionRouter", "iframeUrl", "showLeft", "fixedLeft"]),
    date() {
      return Date.now();
    },
  },
  methods: {
    ...mapMutations(["changeState", "changeFixed"]),

    onSelect(name) {
      //设置缓存 ：当前选中的菜单name
      sessionStorage.removeItem("currentMenuList.activeName");
      sessionStorage.setItem("currentMenuList.activeName", JSON.stringify(name));
      this.activeName = name
    },
    onOpenChange(names) {
      //设置缓存 ：当前展开的菜单列表
      sessionStorage.removeItem("currentMenuList.openNames");
      sessionStorage.setItem("currentMenuList.openNames", JSON.stringify(names));
      var childRoutes = this.getChildRoutes()
      if (childRoutes && childRoutes.length > 0) {
        //debugger
        //this.$router.push(childRoutes[0])
      }
    },
    initMenuList() {
      //加载数据洞察模块下的菜单列表 routes数据洞察的所有菜单
      let routes = [];
      for (var i = 0; i < this.$router.options.routes.length - 1; i++) {
        let route = this.$router.options.routes[i];
        if (route.name == "数据洞察") {
          routes = this.$router.options.routes[i].children;
          break
        }
      }
      for (const element of routes) {
        if (this.$route.path == element.path || this.$route.path.indexOf(element.path) >= 0) {
          //判断当前路由是不是根路由的子路由,若是则直接返回此根路由
          this.menuList = element.children
          break
        }
      }
      sessionStorage.removeItem("currentMenuList");
      sessionStorage.setItem("currentMenuList", JSON.stringify(this.menuList));
      return this.menuList
    },
    getChildRoutes() {
      var routes = {
        children: this.$router.options.routes
      };
      var route = this.$route.matched;
      for (var i = 0; i < route.length - 1; i++) {
        routes = routes.children.find((e) => (e.name == route[i].name));
      }
      return routes.children
    },
    goIframeIfIsRootMenu() {
      //跳转对应数据洞察模块根路由的iframe
      switch (this.$route.path) {
        case macroEconomy.path:
          this.$router.push(macroEconomy.children[0]);
          break;
        case reLand.path:
          this.$router.push(reLand.children[0]);
          break;
        case financialMarket.path:
          this.$router.push(financialMarket.children[0]);
          break;
        case city.path:
          this.$router.push(city.children[0]);
          break;
        case enterprise.path:
          this.$router.push(enterprise.children[0]);
          break;
        case publicSentiment.path:
          //this.selectRouter(publicSentiment.children[0]);
          break;
      }
      this.$nextTick(() => {//异步更新路由
        this.activeName = this.$route.path

      });


    },
    //获取帆软页面对应的pageId
    beforeunloadHandler(e) {
      const pageId = sessionStorage.getItem('pageId');
      this.$pagelog(pageId, 'leave', this, true)
    }
  },
  watch: {
    $route() {
      /*  //路由变动时先移除缓存
        sessionStorage.removeItem("currentMenuList");
        sessionStorage.removeItem("currentMenuList.activeName");
        sessionStorage.removeItem("currentMenuList.openNames");*/
      /* //拼接ifame的路由
       this.changeIframeSrc()*/
      //路由改变时刷新绑定的左边菜单
      this.initMenuList()
      //上方菜单非首次点击时跳转帆软
      this.goIframeIfIsRootMenu()

    }
  },
  beforeMount() {

    //上方菜单非首次点击时跳转帆软
    this.goIframeIfIsRootMenu()
    //加载前从缓存中获取菜单和展开和选中项
    let cachedMenuList = JSON.parse(sessionStorage.getItem("currentMenuList"));
    let cachedActiveName = JSON.parse(sessionStorage.getItem("currentMenuList.activeName"));
    let cacheOpenNames = JSON.parse(sessionStorage.getItem("currentMenuList.openNames"));
    this.menuList = cachedMenuList
    // this.activeName = cachedActiveName
    this.openNames = cacheOpenNames
  },
  mounted() {
    this.$store.dispatch("initPublicData");
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  }
  ,
  destroyed() {
    let pageId = Number(sessionStorage.getItem('pageId'))
    this.$pagelog(pageId, 'leave', this)
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  }
}
  ;
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

// .router-move-enter {
//     transform: translate3d(-100%, 0, 0);
// }
// .router-move-leave-to {
//     transform: translate3d(-100%, 0, 0);
// }
// .router-move-enter-active,
// .router-move-leave-active {
//     transition: transform 0.3s;
// }
.iframe_box {
  border: 0;
}


.insightBox {
  // width: 100%;
  // height: 100%;
  // box-sizing: border-box;
  // margin: 0 auto;
  // background: #fff;
  // display: flex;

// .leftChildNav{
// padding-right: 5px;
// }

  // .rightContent {
  //   height: 100%;
  //   overflow: auto;
  //   flex: 1;
  //   position: relative;

  //   .openNav {
  //     position: absolute;
  //     width: 42px;
  //     height: 16px;
  //     left: -16px;
  //     top: 20px;
  //     transform: rotate(90deg);
  //     cursor: pointer;
  //     z-index: 100000;
  //   }
  // }
}
</style>
